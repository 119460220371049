.main-header-nameplate--active { 
	opacity:1;
	transform: translateY(0);
	transition:opacity .6s .3s ease-in, transform .4s .3s ease-out;
}
.navigation-menu--active {
	opacity:1;
	transform: translateX(0);
	transition:opacity .6s .4s ease-out, transform .4s .4s ease-out;
	@media screen and (max-width:em(425)) {
		top:rem(160);
	}
}
.nav-menu-button-icn--open {
	display:none;
}
.nav-menu-button-icn--closed {
	display:block;
}
.nav-menu-button--active {
	margin:0 0 rem(15);
	a{color:$x-light-aqua}
	.nav-menu-button-icn--open {
		display:block;
		fill:$x-light-aqua;
	}
	.nav-menu-button-icn--closed {
		display:none;
	}
}
.nav-list--open {
	@media screen and (max-width:rem(560))	{
		height:rem(136);
		opacity: 1;
		padding:rem(25) 0 0;
		transform: scale(1);
		transition: height .3s ease-in-out, opacity .3s ease-in .2s, transform .2s cubic-bezier(.44,.4,.29,1.48); 
	}
}
.nav-list--close {
	height:rem(0);
	opacity: 0;
	transform: scale(.8);
	transition: height .2s ease-in .2s, opacity .2s ease-in .2s, transform .1s ease-in;
}
.menu-container--active {
	.scroll-progress-wrapper {
		display:none;
	}
}