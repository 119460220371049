.site-footer-active {
	opacity:1;
	transition:opacity .3s ease-in;
	transition-delay: .5s;
	}
.footer-links {
	width:100%;
	margin:0 0 rem(50) 0;
	position:relative;
	display:flex;
	justify-content: center;
	li {
		width:rem(55);
		height:rem(55);
		margin:0 rem(20) 0;
		box-shadow: 0 rem(18) rem(75) 0 rgba($dark, .3);
		flex-grow:0;
		border-radius:50%;
		background-color:white;
		text-align:center;
		overflow:hidden;
		a {
			display:block;
			width:rem(55);
			height:rem(55);
			transition:background-color .2s ease-in;
			background-image:none;
				&:hover {
				background-color:$aqua;
				color:white;
				.footer-icon { fill:white }
		}
	}
	@media screen and (max-width:em(400)) { margin:40px 10px 0;	}
	@media ( prefers-color-scheme: dark ) {
		box-shadow:none;
		background-color: $dm-light;
	}
}
}
.footer-icon {
	width:rem(20);
	height:rem(20);
	fill:$aqua;
	transition: fill .2s;
	margin-top:rem(18);
}
.site-footer {
	.mail-link, .linked-in-link, .github-link {
		opacity:0;
		transform: scale(.1,.1);
	}
}
.site-footer-active {
	.mail-link {
		transition:opacity 0 .5s ease-in;
		transition-duration: .4s;
		transition-delay: .5s;
	}
	.linked-in-link {
		transition:opacity 0 .7s ease-in;
		transition-duration: .4s;
		transition-delay: .7s;
	}
	.github-link {
		transition:opacity 0 1.1s ease-in;
		transform: scale(1,1);
		transition-duration: .4s;
	}
	.mail-link, .linked-in-link, .github-link {
		opacity:1;
		transition-timing-function: cubic-bezier(.91,-0.01,.29,1.84);
		transform: scale(1,1);
	}
}
.scroll-to-top {
	position:fixed;
	right:rem(15);
	bottom:rem(30);
	width:rem(40);
	height:rem(40);
	border-radius:50%;
	background-color:$aqua;
	text-align:center;
	line-height:rem(40);
	color:white;
	opacity:0;
	background-image:none;
	z-index:1;
	@media screen and (max-width:rem(900)) {display:block;}
		&:hover {
			color:white;
			background-image:none;
			background-color:$dark-aqua;
			transition:background-color .2s;
		}
	}
.scroll-up-icon {
	width:rem(10);
	height:rem(10);
	fill:white;
	margin:rem(15) 0 0 0;
}
.scroll-to-top-inactive {
	opacity:0;
	transition:opacity .2s ease-in;
}
.scroll-to-top-active {
	opacity:.9;
	transition:opacity .2s ease-in;
}
.footer-tagline {
	color:$med;
	font-size:em(11);
	text-align:center;
	margin:0 rem(20) rem(5);
	padding:0;
	@media ( prefers-color-scheme: dark ) {
		color:$x-light-aqua;
	}
}
.svg-import {
	height:0;
	width:0;
	overflow:hidden;
}
.footer-navigation-menu {
	width:100%;
	text-align:center;
	margin:0 auto rem(40);
	background-image: linear-gradient(to bottom, transparent 75%, $med 75%);
	background-size: 60% 2px;
	background-origin: padding-box;
	background-position: center 100%;
	background-repeat: no-repeat;
	padding:0 0 25px;
		@media screen and (max-width:em(420)){
		margin:0 auto rem(20);
		}
	li {
		display:inline-block;
		font-family:$sans;
		font-size:em(20);
		margin:rem(40) 0 0;
		text-transform:capitalize;
		font-weight:700;
			@media screen and (max-width:em(450))	{
			font-size:em(16);
			}
			&::after {
			content:' | ';
			display:inline-block;
			margin:0 rem(15);
			color:$light;
			letter-spacing: -.2em;
				@media screen and (max-width:em(420)){
				margin:0 rem(5);
				}
			}
			&:nth-child(4){
				&::after	{content:''; margin:0;}
			}
			@media screen and (max-width:em(420)) {
				margin:rem(20) 0 0;
				font-size:em(16);
			}
		a {
			color:$med;
			background:none;
			text-shadow:none;
			&:hover { color:$aqua;}
		}
		@media ( prefers-color-scheme: dark) {
			a {
				color:$light_aqua;
			}
		}
	}
}
em {
	.footer-navigation-menu {	
		font-style: normal;
	}
	.footer-tagline {
		font-style: normal;
	}
}