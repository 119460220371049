figure {
    img {
        width: 100%;
        background-color: $xxlight;
    }
}
img {
    display: block;
    a {
        border: 0;
    }
}
a {
    img {
        border: 0;
    }
    &:focus {
        box-shadow: none;
        background: $x-light-aqua !important;
        box-shadow: 0 0 rem(8) $x-light-aqua;
        outline-style: none;
        color: black !important;
    }
}
p,
h1,
h2,
h3,
h4,
li,
a,
code,
sup,
span {
    &::selection {
        background: lighten($aqua, 50);
        /* WebKit/Blink Browsers */
    }

    &::-moz-selection {
        background: #ffb7b7;
        /* Gecko Browsers */
    }
}